import * as Sentry from "@sentry/browser";

const sentryKey = process.env.SENTRY_DSN;
const railsEnv = process.env.RAILS_ENV;

window.Sentry = Sentry;

if (['staging', 'production'].includes(railsEnv)) {
  window.Sentry.init({
    dsn: sentryKey,
    environment: railsEnv,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.6, //Defined at 0.6 (60 %) for logged transactions at monitoring.
  });
}
